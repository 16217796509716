const authRequests = {
  fetchLogin: "user/login",
  postImageUpload: "image/imageUpload",
  fetchRegistration: "user/createuser",
  postForgotPassword: "auth/forgotPassword",
  postVerifyOtp: "auth/verifyotp",
  forgotPasswordVerifyOtp: "auth/forgotPasswordVerifyOtp",
  postResetPassword: "auth/resetPassword",
  postCreateUser: "user/create-user",
  postCustomer: "user/stripe-customer",
  fetchPlans: "user/plan",
  postSubscription: "user/subscription",
  pdfGenrateForuser: "/user/pdfGenerate",
  allDataForlink: "/user/all-data",
  allTipsWithoutToken: "/tips/all-tips",
  fetchOOPPlan: "/user/optional-plan?id=600ac0c143d17065da86f5a9", //live/
  //   fetchOOPPlan: "/user/optional-plan?id=6142e326d879d43a0aab608f",   //test
  clientPdfSubscription: "/user/book-subscription",
  createSwishPayment: "/user/create-swish-payment",
  getPaymentStatus: "/user/retrive-swish-data",
  postOptionalPlan: "/user/book-optional-plan",
  updatePlanDate: "/user/update-plan-date",
  emailValidation: "user/check-user",
  fetchActionSummaryExe: "user/userActionSummaryExerciseDetail",
  fetchFunctionalAnalysisExe: "user/userFunctionalAnalysisDetail",
};

const clientRequests = {
  changeUserPassword: "/user/passwordCheck",
  fetchUpdateStripePlan: "/user/findUpdatePlan",
  fetchBundlePlans: "user/findPlanBundle",
  fetchOnlineProgramPlans: "/user/onlineProgramPlan",
  purchaseOPPlan: "/user/purchaseOOPlan",
  fetchUserDetails: "user/find-user",
  updateUserProfile: "user/updateUser/",
  updateSubscription: "/user/updateSubscription",
  addNewCardDetails: "/user/addNewCardDetails",
  fetchClients: "client/findClientByTherapist",
  findArchiveClientByTherapist: "client/findArchiveClientByTherapist",
  findTherapistById: "client/findTherapistById/",
  addClient: "client/createclient",
  addQuickClient: "client/createquickclient",
  checkIfFormFilled: "client/checkFormFilled",
  deleteClient: "client/deleteclient",
  updateClient: "client/updateclient",
  searchClient: "client/searchclient",
  findClientById: "client/findClientById",
  checkProgramLimit: "user/checkProgramLimit/",
  useProgramLimit: "user/useProgramLimit",
  addAndRemoveClientInArchive: "client/addAndRemoveClientInArchive",
  updateClientVisit: "client/updateClientVisit",
  updateClientOmVisit: "client/updateClientOMVisits"
};

const programRequests = {
  fetchPrograms: "program/findProgramList",
  createPrograms: "program/createClientProgram",
  fetchPreProgram: "/user/preSetProgramList",
  deletePrograms: "program/deleteProgram",
  updatePrograms: "/program/updateProgram/",
  filterProgramsExerciseByVersion: "/program/searchExerciseByVersion",
  fetchFreeProgramLimit: "/program/fetchFreeProgramLimit/",
  freeProgramForBuser: "user/subscription-for-buser",
};

const exerciseRequests = {
  createExercise: "/exercise/create-exercise",
  deleteExercise: "/exercise/deleteExercise/",
  updateExercise: "/exercise/update-exercise/",
  fetchGlobalExercises: "exercise/listOfExercise",
  fetchExerciseByVersion: "exercise/seachByVersion",
  fetchTreatments: "treatment/user-treatment-list",
  fetchPlaylist: "/treatment/user-fetchPlayListById",
};
const buserRequests = {
  verifyBuserUser: "/buser/verifyBuserUser",
  getAllExercise: "/buser/buserListOfExercise",
  generatePdf: "/buser/buserPdfGenerate",
  getPaticularProgram: "/buser/buserProgramDetail",
};

const languageRequests = {
  getLanguages: "/user/get-language",
  getLanguageJsonFile: "/admin/getUpdate-language?selectedLanguage=",
};

const tipsRequest = {
  fetchTips: "/tips/listOfTips",
  createTips: "/tips/create-tips",
  deleteTips: "/tips/deleteTips/",
  updateTips: "/tips/update-Tips/",
};
const stripeRequest = {
  checkOutSession: "/user/checkout-session",
  checkoutForOOPlan: "/user/checkoutForOOP",
  retriveSession: "/user/retrive-session",
  retriveSessionForOOP: "/user/retriveSessionForOOP",
  retriveSessionForPro: "/user/retriveSessionForPro",
  fetchExpiredDate: "/user/retrive-subscription",
  updateOnlineProgramSubscription: "/user/optional-program-update",
  cancleSubscription: "/user/cancel-subscription",
  cancleScheduleSubscription: "/user/cancel-schedule-subscription",
  manageBilling: "/user/manageBilling",
};

export {
  authRequests,
  clientRequests,
  programRequests,
  exerciseRequests,
  tipsRequest,
  languageRequests,
  stripeRequest,
  buserRequests,
};
