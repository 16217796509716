import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { SET_AUTH } from "./redux/actions";

const PrivateRoute = ({
  component: Component,
  allowAccess = true,
  ...rest
}) => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const CheckSubscribed =
    auth instanceof Object && auth?.user?.subscriptionExpired;

  const isAuthenticated = () => {
    if (auth) {
      const decodedToken = jwt_decode(auth.token);
      if (decodedToken.exp * 1000 < Date.now()) {
        dispatch(SET_AUTH(""));
        return false;
      }
      return true;
    }
    return false;
  };

  if (!allowAccess) {
    // If `allowAccess` is false, redirect to "/"
    return <Redirect to="/" />;
  }

  if (CheckSubscribed) {
    // If the subscription is expired, redirect to "/updatestripe"
    return <Redirect to="/updatestripe" />;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
