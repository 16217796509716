import React, { lazy, Suspense } from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { suspenseFallbackLoader } from "./components/common/loaders/Loaders";
import "./App.scss";
import PrivateRoute from "./PrivateRoute";

import CreateDraftForSelf from "./components/pages/Draft/CreateDraftForSelf";
import OptimumProRoute from "./OptimumProRoute";
import BuserRoute from "./BuserRoute";
import { useSelector } from "react-redux";

const Home = lazy(() => import("./components/pages/auth/login/Login"));
const ForgotPassword = lazy(() =>
  import("./components/pages/auth/forgotPassword/ForgotPassword")
);
const OtpVerification = lazy(() =>
  import("./components/pages/auth/optVerification/OtpVerification")
);
const ResetPassword = lazy(() =>
  import("./components/pages/auth/resetPassword/ResetPassword")
);
const Registration = lazy(() =>
  import("./components/pages/auth/signUp/signUp")
);
const UpdateStripe = lazy(() =>
  import("./components/pages/client/myProfile/Stripe/Stripe")
);
const ChangeStripeDetails = lazy(() =>
  import("./components/pages/client/myProfile/stripeForm/StripeFrom")
);
const ClientHome = lazy(() =>
  import("./components/pages/client/clientList/ClientList")
);
const ArchiveList = lazy(() =>
  import("./components/pages/client/clientList/ArchiveList")
);
const MyAccount = lazy(() =>
  import("./components/pages/client/myProfile/MyAccount")
);
const selfPrograms = lazy(() =>
  import("./components/pages/programs/selfPrograms/selfPrograms")
);
const clientPrograms = lazy(() =>
  import("./components/pages/programs/clientPrograms/ClientPrograms")
);
const Exercises = lazy(() =>
  import("./components/pages/Exercises/exerciseList/ExerciseList")
);
const ClientExercises = lazy(() =>
  import("./components/pages/Exercises/ClientExerciseList/ClientExerciseList")
);
const BuserExerciseList = lazy(() =>
  import("./components/pages/Exercises/BuserExerciseList/BuserExerciseList")
);
const SelfExercises = lazy(() =>
  import("./components/pages/Exercises/selfExercises/SelfExercises")
);
const TipsListAdd = lazy(() => import("./components/pages/Tips/TipsListAdd"));
const CreateDraft = lazy(() => import("./components/pages/Draft/CreateDraft"));
const CreateBuserDraft = lazy(() =>
  import("./components/pages/Draft/CreateBuserDraft")
);
const CreateSelfDraft = lazy(() =>
  import("./components/pages/Draft/CreateDraftForSelf")
);
const GeneratedProgram = lazy(() =>
  import("./components/pages/GeneratedProgram/index")
);
const ExerciseDescription = lazy(() =>
  import("./components/pages/Exercises/exerciseDescription/ExerciseDescription")
);
const BuserExerciseDescription = lazy(() =>
  import("./components/pages/Exercises/exerciseDescription/BuserExeDesc")
);
const ExerciseDescriptionLink = lazy(() =>
  import(
    "./components/pages/GeneratedProgram/exerciseDescription/ExerciseDescription"
  )
);
const CreateClient = lazy(() =>
  import("./components/pages/client/createClient/CreateClient")
);
const UpdateClient = lazy(() =>
  import("./components/pages/client/createClient/updateClient/UpdateClient")
);
const FunctionalAnalysisForm = lazy(() =>
  import(
    "./components/pages/client/functionalAnalysisForm/FunctionalAnalysisForm"
  )
);
const ActionSummary = lazy(() =>
  import("./components/pages/client/actionSummary/ActionSummary")
);
const ClientAddedSuccessfully = lazy(() =>
  import("./components/pages/client/createClient/clientAddedSuccessfully")
);

const CreateClientForm = lazy(() =>
  import("./components/pages/client/createClientForm")
);

const LoginAgain = lazy(() =>
  import("./components/common/DialogBox/LoginAgain")
);

function Routes() {
  const isOptimumPro = useSelector((state) => state.userData.isOptimumPro);
  const trainerAccess = useSelector((state) => state.userData?.trainerAccess);
  const appLoader = suspenseFallbackLoader();

  const trainingDoc =
    isOptimumPro &&
    trainerAccess &&
    (trainerAccess === "both" || trainerAccess === "training")
      ? true
      : false;

  const mindDoc =
    isOptimumPro && trainerAccess && trainerAccess === "mind" ? true : false;

  return (
    <>
      <Router>
        <Suspense fallback={appLoader}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/forgotPassword" component={ForgotPassword} />
            <Route exact path="/otpVerification" component={OtpVerification} />
            <Route exact path="/resetPassword" component={ResetPassword} />
            <Route exact path="/signUp" component={Registration} />
            <Route exact path="/updatestripe" component={UpdateStripe} />
            <Route
              exact
              path="/updateCardDetails"
              component={ChangeStripeDetails}
            />
            <BuserRoute
              exact
              path="/Buser/createProgram"
              component={BuserExerciseList}
            />
            <BuserRoute
              exact
              path="/Buser/createDraft"
              component={CreateBuserDraft}
            />
            <BuserRoute
              exact
              path="/Buser/exercise/description"
              component={BuserExerciseDescription}
            />
            <PrivateRoute exact path="/clients" component={ClientHome} />{" "}
            <PrivateRoute
              allowAccess={trainingDoc}
              exact
              path="/archiveClients"
              component={ArchiveList}
            />{" "}
            {/* Therapist client route */}
            <PrivateRoute exact path="/account" component={MyAccount} />{" "}
            {/* Therapist client route */}
            <PrivateRoute
             allowAccess={trainingDoc}
              exact
              path="/client/:name"
              component={clientPrograms}
            />{" "}
            {/* Therapist client route */}
            <PrivateRoute
             allowAccess={trainingDoc}
              exact
              path="/client/:name/tips"
              component={TipsListAdd}
            />{" "}
            {/* Therapist client route */}
            <PrivateRoute
              allowAccess={trainingDoc}
              exact
              path="/client/:name/createdraft"
              component={CreateDraft}
            />
            <PrivateRoute
              allowAccess={trainingDoc}
              exact
              path="/programs/:name"
              component={selfPrograms}
            />{" "}
            {/* Therapist Route */}
            <PrivateRoute
              allowAccess={trainingDoc}
              exact
              path="/programs/:name/:id"
              component={SelfExercises}
            />
            {/* Therapist Route */}
            <PrivateRoute
              allowAccess={trainingDoc}
              exact
              path="/self/:name/createdraft"
              component={CreateSelfDraft}
            />
            <PrivateRoute
              allowAccess={trainingDoc}
              exact
              path="/client/:name/:id"
              component={ClientExercises}
            />{" "}
            {/* Menu Done */}
            <PrivateRoute exact path="/exercises" component={Exercises} />{" "}
            {/* Menu Done */}
            <Route
             allowAccess={trainingDoc}
              exact
              path="/generatedprogram"
              component={GeneratedProgram}
            />
            <Route
              exact
              path="/link/exercise/description"
              component={ExerciseDescriptionLink}
            />
            <Route
              exact
              path="/exercise/description"
              component={ExerciseDescription}
            />
            {/* <Route exact path='/createclient/:id' component={CreateClient} />  */}
            <Route
              exact
              path="/clients/update_client/:id"
              component={UpdateClient}
            />
            <OptimumProRoute
              exact
              path="/clients/functional_analysis"
              component={FunctionalAnalysisForm}
            />
            <OptimumProRoute
              exact
              path="/clients/action_summary"
              component={ActionSummary}
            />
            <Route
              exact
              path="/clients/success"
              component={ClientAddedSuccessfully}
            />
            {/* <Route exact path="/clientform/:id" component={CreateClientForm} /> */}
            <Route
              exact
              path="/createclient/:id"
              component={CreateClientForm}
            />
          </Switch>
          <LoginAgain />
        </Suspense>
      </Router>
    </>
  );
}

export default Routes;
